import axios from "axios";

const http = axios.create();

export const setupInterceptors = () => {
    try {
        http.interceptors.response.use(
            res => {
                // success
                return res
            }, 
            err => {
                const { status } = err.response
    
                if (status === 401) {
                    // here we have access of the useHistory() from current Router
                    window.location.assign('bff/login');
                }
    
                return Promise.reject(err)
            });
    } catch (error) {
        console.log(error);
    }
}

export default http