import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap"
import { HashCodeType } from "../helpers/constants";


export default function OtpConfigurationForm({otpConfigurationToEdit, onClose, onSave}){
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const [otpConfig, setOtpConfig] = useState(otpConfigurationToEdit);

    const isNew = () => {
        return (!otpConfig.Id) || otpConfig.Id === 0;
    }

    const onSubmit = () => {
        onSave(otpConfig);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOtpConfig(prevState => ({
            ...prevState,
            [name]: value
        }) );
    }

    // Validate fields on initial load
    useEffect(() => {
        if (!isNew) {
            trigger();
        }
    }, [trigger]);

    return(
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup row>
                <Label for="nameInput">Name</Label>
                <Input id="nameInput" value={otpConfig.Name} {...register("Name", { required: true, maxLength:255, onChange:handleChange })} />
            </FormGroup>
            <FormGroup row>
                <Label for="userNameInput">User Name</Label>
                <Input id="userNameInput" value={otpConfig.UserName} {...register("UserName", { required: true, maxLength:255, onChange:handleChange })} />
            </FormGroup>
            <FormGroup row>
                <Label for="clientSecretInput">Client Secret</Label>
                <Input id="clientSecretInput" placeholder={ isNew() ? "" : "********************"} {...register("ClientSecret", { required: isNew(), maxLength:1024, onChange:handleChange })} />
            </FormGroup>
            <FormGroup row>
                <Label for="otpHashModeInput">Hash Mode</Label>
                <Input id="otpHashMode" type="select" value={otpConfig.OtpHashMode} {...register("OtpHashMode", { required: true, onChange:handleChange })}>
                {Object.keys(HashCodeType).map(k =>
                    <option key={k} value={HashCodeType[k]}>{HashCodeType[k]}</option> 
                )}
                </Input>
            </FormGroup>
            <FormGroup row>
                <Label for="sizeInput">Size</Label>
                <Input id="sizeInput" type="number" value={otpConfig.Size} {...register("Size", { required: true, onChange:handleChange })} />
            </FormGroup>
            <FormGroup row>
                <Label for="stepInput">Step</Label>
                <Input id="stepInput" type="number" value={otpConfig.Step} {...register("Step", { required: true, onChange:handleChange })} />
            </FormGroup>
            <FormGroup check row>
                <Col
                    sm={{
                        offset: 9,
                        size: 3
                    }}>
                    <Button className="me-2" onClick={onSubmit}>Submit</Button>
                    <Button className="me-2" onClick={onClose}>
                        Cancel
                    </Button>
                </Col>
            </FormGroup>
        </Form>
    )
}