import http from '../helpers/Http';
import { errorLogger } from './errorLogger';

const baseUrl = "/api/OtpConfiguration";

export async function getOtpConfigurationById(id){
    let response = null;
    const url = `${baseUrl}/${id}`;
    try{
        const res = await http.get(url);
        response = res.data.results;
    } catch (error){
        errorLogger(error);
        throw(error);
    }
    return response;
}

export async function getOtpConfigurations(oDataParams)
{
    let response = null;
    const url = `${baseUrl}`;
    let oData = {};
    if(oDataParams && oDataParams.select){
        oData.$select = oDataParams.select
    }
    if(oDataParams && oDataParams.filter){
        oData.$filter = oDataParams.filter
    }
    if(oDataParams && oDataParams.expand){
        oData.$expand = oDataParams.expand
    }
    if(oDataParams && oDataParams.orderby){
        oData.$orderby = oDataParams.orderby
    }

    try{
        const res = await http.get(url, { params: oData });
        console.log(res);
        response = res.data;
    } catch (error){
        errorLogger(error);
        throw(error);
    }
    return response;
}

export async function updateOtpConfigurations(otpConfig)
{
    let response = null
    const url = `${baseUrl}`;

    try{
        const res = await http.put(url, otpConfig);
        console.log(res);
        response = res.data;
    } catch (error) {
        errorLogger(error);
        throw error;
    }
    return response;
}

export async function createOtpConfiguration(otpConfig)
{
    let response = null
    const url = `${baseUrl}`;

    try{
        const res = await http.post(url, otpConfig);
        console.log(res);
        response = res.data;
    } catch (error) {
        errorLogger(error);
        throw error;
    }
    return response;
}

export async function deleteOtpConfiguration(id)
{
    let response = null
    const url = `${baseUrl}/${id}`;

    try{
        const res = await http.delete(url);
        console.log(res);
        response = res.data;
    } catch (error) {
        errorLogger(error);
        throw error;
    }
    return response;
}

export async function generateCode(id)
{
    let response = null
    const url = `${baseUrl}/generatepassword/${id}`;

    try{
        const res = await http.get(url);
        console.log(res);
        response = res.data;
    } catch (error) {
        errorLogger(error);
        throw error;
    }
    return response;
}