import React, { Component } from 'react';
import { getOtpConfigurations, updateOtpConfigurations, createOtpConfiguration, deleteOtpConfiguration, generateCode } from "../services/OtpConfiguration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OtpConfigurationForm from "./OtpConfigurationForm"
import { HashCodeType } from "../helpers/constants";
import { Button } from "reactstrap"
import SimpleTooltip from "./SimpleTooltip"
import OtpCopyModal from "./OtpCopyModal"


export class OtpConfigurationList extends Component {
  static displayName = OtpConfigurationList.name;

  constructor(props) {
    super(props);
    this.state = { 
      otpConfigs: [], 
      loading: true,
      editingOtpConfig: null,
      generatedOtp: null
     };
  }

  componentDidMount() {
    this.populateOtpConfigData();
  }

  handleClose() {
    this.setState({
      editingOtpConfig: null
    });
  }

  async handleSave(optConfig) {
      if (optConfig.Id)
    {
        const response = await updateOtpConfigurations(optConfig);
        this.setState((prevState) => ({
          otpConfigs: prevState.otpConfigs.map((prevConfig) => {
                if (prevConfig.Id === optConfig.Id) {
                    return response;
                }
                return prevConfig;
            })
        }))
      console.log("handleSave Update", response);
    }
    else
    {
      const response = await createOtpConfiguration(optConfig);
      this.setState((prevState) => ({
        otpConfigs: [...prevState.otpConfigs, response]
      }));
      console.log("handleSave Create", response);
    }

    this.handleClose();
  }

  handleCreate(){
    this.setState({
      editingOtpConfig: {
        Name: "",
        UserName: "",
        ClientSecret: "",
        OtpConfigurationType: "totp",
        OtpHashMode: "sha1",
        Size: 6,
        Step: 30
      }
    });
  }

  async deleteConfig(id){
    const response = await deleteOtpConfiguration(id);
    this.setState((prevState) => ({
      otpConfigs: prevState.otpConfigs.filter((config) => config.Id !== id)
    }));
    console.log("deleting " + id);
  }

  async generateCode(id){
    const response = await generateCode(id);
    this.setState({
      generatedOtp: response.TemporaryPassword
    });
    console.log(response);
  }

  editConfig(config){
    this.setState({
      editingOtpConfig: config
    });
  }

  async populateOtpConfigData() {
    const response = await getOtpConfigurations();
    console.log(response);
    this.setState({ otpConfigs: response, loading: false });
  }

  closeModal(){
    this.setState({
      generatedOtp: null
    });
  }

  renderOtpConfigsTable(otpConfigs) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>User Name</th>
            <th>Hash Mode</th>
            <th>Size</th>
            <th>Step</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {otpConfigs.map(otpConfig =>
            <tr key={otpConfig.Id}>
              <td>{otpConfig.Name}</td>
              <td>{otpConfig.UserName}</td>
              <td>{ (otpConfig.OtpHashMode in HashCodeType ? HashCodeType[otpConfig.OtpHashMode] : otpConfig.OtpHashMode) }</td>
              <td>{otpConfig.Size}</td>
              <td>{otpConfig.Step}</td>
              <td>
                <Button className="me-1" color="link" id="showEditOtpButton" onClick={this.editConfig.bind(this, otpConfig)}>
                  <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                </Button>
                <SimpleTooltip target="showEditOtpButton">Edit One Time Password Configuration</SimpleTooltip>

                <Button className="me-1" color="link" id="deleteOtpButton" onClick={this.deleteConfig.bind(this, otpConfig.Id)}>
                  <FontAwesomeIcon icon={["fas", "trash-can"]} />
                </Button>
                <SimpleTooltip target="deleteOtpButton">Delete One Time Password Configuration</SimpleTooltip>

                <Button className="me-1" color="link" id="generateCodeButton" onClick={this.generateCode.bind(this, otpConfig.Id)} >
                  <FontAwesomeIcon icon={["fas", "key"]} />
                </Button>
                <SimpleTooltip target="generateCodeButton">Generate One Time Password</SimpleTooltip>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  renderForm(){
    return (<OtpConfigurationForm otpConfigurationToEdit={this.state.editingOtpConfig} onClose={this.handleClose.bind(this)} onSave={this.handleSave.bind(this)} />)
  }

  renderGrid(){
    return(
    <div>
      <Button onClick={this.handleCreate.bind(this)}>Create</Button>
      {this.renderOtpConfigsTable(this.state.otpConfigs)}
    </div>
    )
  }

  render() {
    let contents = <></>;
    if(this.state.loading)
    {
      contents =  <p><em>Loading...</em></p>
    }
    else if(this.state.editingOtpConfig)
    {
      contents = this.renderForm();
    }
    else 
    {
      contents = this.renderGrid();
    }
    

    return(
      <>
      {contents}
      <OtpCopyModal isOpen={this.state.generatedOtp !== null} toggle={this.closeModal.bind(this)} generatedOtp={this.state.generatedOtp} />
      </>
    )
    
  }
}
