import React, { Component, useEffect } from 'react';
      
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip, Button, Input, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class OtpCopyModal extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        tooltipOpen: false
    }
  }

  copyPassword(){
    navigator.clipboard.writeText(this.props.generatedOtp);
    this.toggleTooltip();
  }

  toggleTooltip()
  {
    this.setState({tooltipOpen: !this.state.tooltipOpen});
  }

  toggleModal(){
    this.setState({
      tooltipOpen: false,
    });
    this.props.toggle();
  }

  render () {
    return(
        <div>
          <Modal isOpen={this.props.isOpen} toggle={this.toggleModal.bind(this)}>
            <ModalHeader toggle={this.toggleModal.bind(this)}>Generated One Time Password</ModalHeader>
            <ModalBody>
              <InputGroup>
                <Input readOnly value={this.props.generatedOtp ?? ""}></Input>
                <div id="copyOtpText">
                    <Button id="copyOtpTextButton" onClick={this.copyPassword.bind(this)}>
                    <FontAwesomeIcon icon={["fas", "clipboard"]} />
                    </Button>
                </div>
                <UncontrolledTooltip target="copyOtpText" trigger="manual" isOpen={this.state.tooltipOpen} toggle={this.toggleTooltip.bind(this)}>Copied!</UncontrolledTooltip>
              </InputGroup>
            </ModalBody>
          </Modal>
        </div>
    );
  }
}

export default OtpCopyModal;